import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import {Autoplay} from "swiper/modules";

export const Clients = (props) => {
    return (
        <div id="clients">
            <div className="container">
                <div className="section-title text-center">
                    <h2>Our Clients</h2>
                </div>
                <div className="swiper-wrapper">
                    <Swiper
                        modules={[Autoplay]}
                        slidesPerView={3}
                        autoplay={{delay: 300, disableOnInteraction: false}}
                        speed={500}
                        loop={true}
                        breakpoints={{
                            640: {slidesPerView: 2},
                            768: {slidesPerView: 3},
                            1024: {slidesPerView: 4},
                        }}
                    >
                        {props.data
                            ? props.data.map((d, i) => (
                                <SwiperSlide key={i} className="swiper-slide">
                                    <img
                                        src={d.img}
                                        alt={`Client-${i}`}
                                        className="client-logo"
                                        style={{width: "100%", height: "auto", objectFit: "contain"}}
                                    />
                                </SwiperSlide>
                            ))
                            : "loading"}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};
