import React, {useEffect} from "react";

export const Navigation = () => {
    useEffect(() => {
        const $ = window.jQuery;

        const links = document.querySelectorAll(".navbar-collapse a");
        links.forEach(link => {
            link.addEventListener("click", () => {
                const navCollapse = $(".navbar-collapse");
                if (navCollapse.hasClass("in")) {
                    navCollapse.collapse("hide");
                }
            });
        });

        return () => {
            links.forEach(link => {
                link.removeEventListener("click", () => {});
            });
        };
    }, []);

    return (
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                    >
                        {" "}
                        <span className="sr-only">Toggle navigation</span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                        <span className="icon-bar"></span>{" "}
                    </button>
                    <a className="custom-logo-link" href="https://www.djavafactory.com/">
                        <img width="180" height="58"
                             src="/img/dJavaFactory_logo_x180.png"
                             className="custom-logo" alt="dJava Factory"/>
                    </a>{" "}
                </div>

                <div
                    className="collapse navbar-collapse"
                    id="bs-example-navbar-collapse-1"
                >
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <a href="https://www.djavafactory.com/#services" className="page-scroll">
                                Services
                            </a>
                        </li>
                        <li>
                            <a href="https://www.djavafactory.com/#clients" className="page-scroll">
                                Clients
                            </a>
                        </li>
                        <li>
                            <a href="https://www.djavafactory.com/#about" className="page-scroll">
                                About
                            </a>
                        </li>
                        <li>
                            <a href="https://www.djavafactory.com/#directors" className="page-scroll">
                                Directors
                            </a>
                        </li>
                        <li>
                            <a href="https://www.djavafactory.com/#contact" className="page-scroll">
                                Contact
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
