import React from "react";

export const Services = (props) => {
    return (
        <div id="services" className="text-center">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h2>What can djava do for you?</h2>
                    <p>
                        We're a boutique software company where Java is our passion!
                    </p>
                </div>
                <div className="row">
                    {props.data
                        ? props.data.map((d, i) => (
                            <div key={`${d.name}-${i}`} className="col-md-4">
                                {" "}
                                <i className={d.icon}></i>
                                <div className="service-desc">
                                    <h3>{d.name}</h3>
                                    <p>{d.text}</p>
                                </div>
                            </div>
                        ))
                        : "loading"}
                </div>
            </div>
        </div>
    );
};
