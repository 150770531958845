import React, {useState} from "react";
import emailjs from "emailjs-com";

const initialState = {
    name: "", email: "", subject: "", message: "",
};
export const Contact = (props) => {
    const [setState] = useState(initialState);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState((prevState) => ({...prevState, [name]: value}));
    };
    const clearState = () => setState({...initialState});

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm("service_wt98ihz", "template_w6f8a1n", e.target, "VdU7OWfeW7XlwmBuc")
            .then((result) => {
                console.log(result.text);
                alert("Email sent successfully!");
                clearState();
            })
    };


    return (<div>
        <div id="contact">
            <div className="container">
                <div className="col-md-8">
                    <div className="row">
                        <div className="section-title">
                            <h2>How May We Code For You?</h2>
                            <p>
                                Please fill out the form below to send us an email and we will
                                get back to you as soon as possible.
                            </p>
                        </div>
                        <form name="sentMessage" validate onSubmit={handleSubmit}>
                            <div className="row form-container">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="form-control"
                                            placeholder="Your Name (required)"
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Your Email (required)"
                                            required
                                            onChange={handleChange}
                                        />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input
                                    type="subject"
                                    id="subject"
                                    name="subject"
                                    className="form-control"
                                    placeholder="Subject"
                                    onChange={handleChange}
                                ></input>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className="form-group">
                  <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Your Message"
                      required
                      onChange={handleChange}
                  ></textarea>
                                <p className="help-block text-danger"></p>
                            </div>
                            <div id="success"></div>
                            <button type="submit" className="btn btn-custom btn-lg">
                                Send
                            </button>
                        </form>
                    </div>
                </div>
                <div className="col-md-3 col-md-offset-1 contact-info">
                    <div className="contact-item">
                        <h3>Contact Info</h3>
                        <p>
      <span>
        <i className="fa fa-map-marker"></i> Address
      </span>
                            {props.data ? props.data.address : "loading"}
                        </p>
                    </div>
                    <div className="contact-item">
                        <p>
      <span>
        <i className="fa fa-phone"></i> Phone
      </span>{" "}
                            {props.data ? props.data.phone : "loading"}
                            <br/>
                            {props.data ? props.data.salesPhone : "loading"}
                        </p>
                    </div>
                    <div className="contact-item">
                        <p>
      <span>
        <i className="fa fa-envelope-o"></i> Email
      </span>{" "}
                            {props.data ? props.data.email : "loading"}
                        </p>
                    </div>
                    <div className="contact-item">
                        <p>
      <span>
        <i className="fa fa-clock-o"></i> Working Hours
      </span>
                            {props.data ? props.data.workingHours : "loading"}
                        </p>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row">
                        <div className="social">
                            <ul>
                                <li>
                                    <a href={props.data ? props.data.facebook : "/"}>
                                        <i className="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={props.data ? props.data.twitter : "/"}>
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href={props.data ? props.data.linkedin : "/"}>
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="footer">
            <div className="container text-center">
                <p>
                    Copyright &copy; {new Date().getFullYear()}{" "}
                    <a href="https://www.djavafactory.com/" rel="nofollow">
                        dJava Factory
                    </a>
                </p>
            </div>
        </div>
    </div>);
};
