import React from "react";

export const About = (props) => {
    return (
        <div id="about">
            <div className="container d-flex justify-content-center align-items-center flex-column">
                <div className="row">
                    <div className="col-xs-12 mx-auto text-center">
                        <div className="about-text">
                            <h2>About Djava</h2>
                            <div dangerouslySetInnerHTML={{__html: props.data ? props.data.paragraph : "loading..."}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
