import React from "react";

export const Directors = ({data}) => {
    return (
        <div id="directors" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Our Team</h2>
                </div>
                <div className="row">
                    <div className="portfolio-items">
                        {data ? (
                            data.map((d, i) => (
                                <div key={i} className="col-md-4">
                                    <div className="team-wrapper">
                                        <div className="team-img">
                                            <img src={d.image} alt={d.name} className="team-photo"/>
                                        </div>
                                        <div className="team-content-wrapper">
                                            <div className="team-desc-wrapper">
                                                <h3 className="team-title">{d.name}</h3>
                                                <div className="team-designation">{d.designation}</div>
                                                <div className="team-divider"></div>
                                                {d.description && <div className="team-content">{d.description}</div>}
                                                <div className="team-social">
                                                    <div className="team-social-block">
                                                        {d.linkedin && (
                                                            <a
                                                                href={d.linkedin || "#"}
                                                                className={`linkedin-button ${!d.linkedin ? "disabled" : ""}`}
                                                                target={d.linkedin ? "_blank" : undefined}
                                                                rel={d.linkedin ? "noopener noreferrer" : undefined}
                                                                onClick={(e) => {
                                                                    if (!d.linkedin) e.preventDefault();
                                                                }}
                                                            >
                                                                <i className="fa fa-linkedin"></i>
                                                            </a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            "Loading..."
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};